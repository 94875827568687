import Information from "../elements/informetion";
import {infoprodukt} from "../ul/infoprodukt.js";
import "./museum.css";
import './responsive.css'


function Museum() {

    return (
        <div className="contener">
            <div className="infobox">
                <div className="infoboxTitle">
                    <div className="boxsize">{infoprodukt.map(itam => {
                        return (
                            <Information
                                key={itam.id}
                                id={itam.id}
                                text={itam.text}
                                title={itam.title}
                                img1={itam.img1}
                                img2={itam.img2}
                            />
                        )
                    })}</div></div>
            </div>
        </div>
    )
}

export default Museum
