import './responsive.css'
import Info from "../elements/info";
import {infocompani} from "../ul/infocompani"
import './contact.css'

function Partners(params) {
    return (
        <div className="contener">
            <div className="contactbox">
                <div className="contactTitle">
                    <img alt='rmg6' className="contimg" src="../img/photo_cont.jpg"></img>
                    <div className="contactItam">
                        <div>
                            {infocompani.map(itam => {
                                return (
                                    <Info
                                        key={itam.id}
                                        id={itam.id}
                                        url={itam.url}
                                        title={itam.title}
                                    />
                                )
                            })} </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Partners
