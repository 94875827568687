import Info from './info.js'
import {infocompani} from "../ul/infocompani.js"
import './home.css'
import {Link} from 'react-router-dom'


function Header() {
    return (
        <div className='logoinfo'>
            <div className='navigetion'>
                <Link to='/'>
                    <div className='logoimg'>
                        <img alt='rmg2' src={require('.//../logo.jpg')}/>
                    </div>
                </Link>
                <div className='primay'>
                    {
                        infocompani.map(itam => {
                            return (
                                <Info
                                    key={itam.id}
                                    id={itam.id}
                                    url={itam.url}
                                    title={itam.title}
                                />
                            )
                    })}
                </div>
            </div>
        </div>
    )

}

export default Header
