import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';


function MyMap() {
    return (
      <YMaps >
     <Map   width="80vw"  height="15vh" defaultState={{ center: [40.225329, 44.497841], zoom: 16 }}>
       <Placemark geometry={[40.225329, 44.497841]} />
     </Map>
   </YMaps>
    );
  }
  
  export default MyMap;