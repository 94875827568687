import {infowork} from "../ul/infowork";
import Work from "../elements/work";
import "./customers.css";
import './responsive.css'

function Customers() {
    return (
        <div className="contener">
            <div className="custbox">
                <div className="custboxTitle">
                    <div className="titlecust"><h1>Մեր Պատվիրատուները</h1></div>
                    <div className="cutsinfo">  {infowork.map(itam => {
                        return (
                            <Work
                                key={itam.id}
                                id={itam.id}
                                text={itam.text}
                                img={itam.img}
                            />
                        )
                    })}</div>
                </div>
            </div>
        </div>
    )

}

export default Customers
