import './product.css';
import Oboute from "../elements/oboute.js";
import './responsive.css'


function Product() {
    return (
        <div className='contener'>
            <div className='homeImage'></div>
            <div className='info'>
                <div className='infotext'><Oboute
                    title={"ԷՌ ԷՄ ՋԻ ՔՆՍԹՐԱՔՇՆ ՍՊԸ"}
                    porograf={"նկերությունը ստեղծվել է 2022թ.–ին՝ հանդիսանում է բազմահարկ շենքերի հավաքովի և միաձույլ երկաթբետոնից շինարարություն, ինչպես նաև երեսապատման ծառայություններ իրականացնող ներկայումս լավագույն ընկություններից մեկը։ Երկաթբետոնը՝բետոնիև պողպատի ամրանի միաձույլ զուգակցումն է, որոնք կոնստրուկցիայում աշխատում են համատեղ։ Երկաթբետոնե կոնստրուկցիաններ և շինվածքները՝ շենքերի և կառույցների տարրեր և այդ տարրերի զուգակցումներն են։ Ժամանակակից երկաթբետոնե կոնստրուկցիանները և շինվածքները դասակարգվում են ըստ մի քանի հատկանիշների կատարման եղանակի (միաձույլ, հավաքովի, հավաքովի միաձույլ), կիրառվող բետոնի տեսակի (ծանր, թեթև, բջջավոր, կրակակայուն և այլն), լարված վիճակի (սովորական և նախալարված)։ Պարզագույն երկաթբետոնե կոնստրուկցիաներ են առանձին հեծանային սալերը, հասարակ հեծանները, սյուները։ Երեսապատման աշխատանքները շինարարական և հարդարման աշխատանքների տեսակներից են։ Պատերի, հատակների և այլ կոնստրուկցիաների երեսապատման միակ և բուն նպատակ կառույցների և մակերեսների գեղեցիկ, էսթետիկ տեսքի ապահովումն է:"}
                    video={'..//img/video.mp4'}
                /></div>
            </div>
        </div>
    );
}

export default Product;
