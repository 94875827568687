import './oboute.css'


function Oboute({title,porograf,video}) {
  
    return (
      <div className="oboute">
        <div className='infortext'><h2>{title}</h2>
        <p><span>Ը</span>{porograf}</p></div>
        <div className='infovideo'><video src={video} controls></video></div>
      </div>
    );
  }
  
  export default Oboute;
  