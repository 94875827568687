import {Link} from "react-router-dom";
import './menubox.css'

function Menu({ text, url, id, activeTab, setActiveTab, isClicked, closeMenu }) {
    const handleTabClick = () => {
        if (activeTab !== id) {
            setActiveTab(id);
        }
    };

    return (
        <div className={`menu-info ${activeTab === id ? 'active' : ''}`}>
            <Link className="link" to={url} onClick={isClicked ? closeMenu : handleTabClick} >
                {text}
            </Link>
        </div>
    );
}

export default Menu
