 export const infocompani=[
    
{   "id": "1",
    "url": "https://img.icons8.com/?size=40&id=48089&format=png&color=000000",
    "title": "Դավթաշեն 2-րդ փ․ տ․27",
},
{   "id": "2",
    "url": "https://img.icons8.com/?size=40&id=79&format=png&color=000000",
    "title": "+374 94 253031",
},
{   "id": "3",
    "url": "https://img.icons8.com/?size=40&id=12580&format=png&color=000000",
    "title": "rmgconstruction2023@gmail.com",
}

 ]