


export const infoprodukt=[

    {   "id": "1",
        "text": "«ԱՐԵՎ» բնակելի համալիրում, որի կառուցապատողն է ՝ «ԲԱՅԱԶԵՏ ՔՈՆՍԹՐԱՔՇՆ» ՍՊԸ–ն , երեսապատման աշխատանքները վստահվել է մեզ՝ կատարվել է սալիկապատման և երեսապատման աշխատանքներ։ Երևանի հենց սրտում ՝ Խանջյան 9/3 հասցեում է  գտնվում այս գեղեցիկ շենքը, որն ունի  3 հասարակական և 14 բնակելի հարկեր։ Պայմանավորված ժամանակում, բարձր որակով կատարվել են պահանջված աշխատանքները, ինչի արդյունքում բնակելի համալիրը ժամանակին հանձնել են շահագործման։",
        "title": "Խանջյան 9/3",
        "img1": "../img/photo_1.jpg",
        "img2": "../img/photo_2.jpg",
        },
    
    {   "id": "2",
        "text": "Երևանի Դավիթ Անհաղթ 6 հասցեյում կառուցվում է բնակելի համալիր, որի կառուցապատողն  է «KR Contruction»  ընկերությունը։ «RMG Construction»-ին վստահվել է երկաթբետոնե կոնստրուկցիայի աշխատանքները։Նախագծում ներառված են բարձր որակաորում ստացած մասնագետներ, որոնք կատարում են որակական չափանիշներին համապատասխան աշխատանքներ սահմանված ժամկետներում։ Աշխատանքները սկսվել են 2023 թվականի հոկտեմբեր ամսին և կատարվում են բնականոն ընթացքով։",
        "title": "Դավիթ Անհաղթ 6",
        "img1": "../img/photo_7.jpg",
        "img2": "../img/photo_8.jpg",
        },

    {   "id": "3",
        "text":"Կոտայքի մարզի, Քասախ համայնքի Ա. Մարտիրոսյան 10 հասցեում կառուցվում է բազմաբնակարան բնակելի շենք, որի կառուցապատողն  է «KR Contruction»  ընկերությունը։ «RMG Construction»-ին վստահվել է երկաթբետոնե կոնստրուկցիաի աշխատանքները , որը սկսվել են կատարվել 2023 թվականի նոյեմբեր  ամսին և համապատասխան աշխատանքները կավարտվեն պայմանավաորված ժամանակում։",
        "title": "Կոտայքի մարզ, Քասախ համայնք Ա. Մարտիրոսյան 10",
        "img1": "../img/photo_5.jpg",
        "img2": "../img/photo_6.jpg",
    },
    {   "id": "4",
        "text": "Կենտրոնից ընդամենը 5 րոպե հեռավորության վրա գտնվող Մոնթե Մելքոնյան 10 պրեմիում դասի բազմաբնակելի համալիրը, որն ունի  18 հարկ, կառուցապատողն է ՝ «Մ․ Լ ՀԱՈՒՍ» ՍՊԸ–ն, պատվիրատու ՝ Հարությունյան Շին 1։ Շենքի երեսապատման աշխատանքները վստահվել է մեզ։ Աշխատանքները սկսվել են 2023 թվականի սեպտեմբեր–հոկտեմբեր ամիսներին։ Համալիրը երեսապատվում է  բարձրորակ շիննյութերով, որի տեսակն ու նյութերն ընտրելիս, հաշվի է առնվում  կառույցի ճարտարապետական ոճին համապատասխան լինելը, երեսապատման նյութի ամրությունը ու դիմացկունությունը, բնակլիմայական պայմանների և արտաքին այլ գործոնների հանդեպ:",
        "title": "Մոնթե Մելքոնյան 10 ",
        "img1": "../img/photo_3.jpg",
        "img2": "../img/photo_4.jpg",
        

    },
    
     ]