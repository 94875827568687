import DesktopNavigation from "./DesktopNavigation";
import MobileNavigation from "./MobileNavigation";

const NavBar = () => {
     return(
         <div>
             <DesktopNavigation />
             <MobileNavigation />
         </div>
     )
 }

 export default NavBar;
