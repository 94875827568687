import {Routes, Route} from 'react-router-dom'
import './App.css';
import Product from '../src/pages/product';
import Museum from './pages/museum';
import Contacts from '../src/pages/contacts';
import Customers from '../src/pages/customers';
import Partners from '../src/pages/partners';
import Header from "./elements/home";
import Footer from "./elements/footer";
import NavBar from "./elements/navbar";


function App() {
    return (
        <>
            <Header />
            <NavBar/>
            <Routes>
                <Route index element={<Product/>}/>
                <Route path='/museum' element={<Museum/>}/>
                <Route path='/product' element={<Product/>}/>
                <Route path='/contacts' element={<Contacts/>}/>
                <Route path='/customers' element={<Customers/>}/>
                <Route path='/partners' element={<Partners/>}/>
            </Routes>
            <Footer/>
        </>
    );
}

export default App;
