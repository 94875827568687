import './info.css'

  function Info ({url, title}) {

    return(
        <div className="infonavigation ">
        <img alt='rmg1' src={url}></img>
        <p>{title}</p>
        </div>

    )
  }

  export default Info
