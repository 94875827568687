export const infopart =[
   
    {   "id": "1",
        "text": " «Կա Էռ Բիլդ» և «Կա Էռ Քնսթրաքշն» ընկերությունները  համարվում են մեր անփոխարինելի գործընկերները: «Կա Էռ Բիլդ»-ը լավագույններից է բետոնի արտադրության մեջ, իսկ «Կա Էռ Քնսթրաքշն» -ը ՝ պեմզաբլոկի և գազաբլոկի արտադրությունների մեջ։ Արտադրությունների մեջ օգտագործվում է բարձրորակ հումք, ինչի արդյունքում արտադրանքը ունենում է ամուր և նմանը չունեցող որակ՝ Հայաստանի շուկայում։ Բետոնը մեզ տրամադրում է ՝ «Կա Էռ բիլդ» ընկերությունը, ինչի արդյունքում շենքերը լինում են շատ ամուր և որակով:",
        "img": "../img/photo.jpg", 
    },
    {   "id": "2",
        "text": "«YM MATERIALS» ընկերության հետ համագործակցում ենք, ստանալով «Свеза» ֆիրմայի նրբատախտակներ և ռուսական բարձրորակ արտադրության կանգնակներ, եռոտանիներ և քառոտանիներ: ",
        "img": "../img/photo_20241.jpg", 
    },
    {   "id": "3",
        "text": "Մեկ այլ գործընկեր է «Peri Armenia»: Նրանք մեզ տրամադրում են լավագույն շինարարական նյութերը, գերմանական արտադրության ՝ նրբատախտակներ , կաղապարի բաղադրիչներ, փայտային համակարգեր և ոչ միայն։",
        "img": "../img/photo_20242.jpg", 
    },
    
  
 ]