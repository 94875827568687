  //DesktopNavigation.js

  import NavLinks from "./navlinks";
  import './navbar.css';

  const DesktopNavigation = () =>{
      return(
          <nav className="DesktopNavigation">
              <NavLinks />
          </nav>
      )
  }

  export default DesktopNavigation;
