import './work.css'

function Work({key,id,text,img}) {
    return(

        <div className="workbox">
                <img alt='rmg5' src={img}></img>
                <p> {text}</p>
        </div>

    )

}
export default Work
