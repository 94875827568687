import './information.css'

function Information({title,text,img1,img2}) {
    return(

        <div className="box">
           <h3>{title}</h3>
            <p className='infoP'>{text}</p>
            <div className='infoImg'>
                <img alt='rmg3'  src={img1}></img>
                <img alt='rmg4' src={img2}></img>
            </div>

        </div>

    )

}
export default Information
