import './navbar.css';
import {infomanue} from "../ul/infomanue";
import Menu from "./menu";
import React, {useState} from "react";

const NavLinks = ({isClicked, closeMenu}) => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <div className="menu-box">
            <div className='menu-list'>
                {infomanue.map(itam => {
                    return (
                        <Menu
                            key={itam.id}
                            id={itam.id}
                            text={itam.text}
                            url={itam.url}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            isClicked={isClicked}
                            closeMenu={closeMenu}
                        />
                    )
                })}
            </div>
        </div>
)
}

export default NavLinks;
