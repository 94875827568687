import Info from "./info"
import MyMap from "./map"
import "./footer.css"
import {infocompani} from "../ul/infocompani"

function Footer(params) {
    return(
        <div className="footer">
            <div className="footerInfo">
            <MyMap/>
                <div className="footerItam">{infocompani.map(itam=>{
                    return (
            <Info
                    key={itam.id}
                    id={itam.id}
                    url={itam.url}
                    title = {itam.title}
            />
          )
          })} </div>
            </div>
        </div>
    )
    
}
export default Footer