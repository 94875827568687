export const infowork =[
   
    {   "id": "1",
        "text": " Մենք համագործակցում ենք հայտի շինարարական ընկերությունների հետ, ինչպիսին են ՝ «KR Construction»-ը, որը նաև հանդիսանում է «KMEW»-ի պաշտոնական ներկայացուցիչը Անդրկովկասում: Սկսած 2008թ.-ից  «KR Construction» կատարում է բնակելի և կոմերիցիոն նշանակություն ունեցող շենքերի նախագծերի իրականացում:  Նրանց կողմից կատարած կառուցապատման նախագծերը կարող եք տեսնել Երևան քաղաքի մի շարք կենտրոնական և ոչ կենտրոնական շրջաններում՝ Գյուլբենկյան 30/3, Լվովյան 2,Կոմիտաս 15, Մաշտոց 13/1 ինչպես նաև Եղվարդի խճուղի 22 հասցեում «Davtashen City» բնակելի թաղամաս։ Ընթացիկ աշխատանքներից է «Ֆիրդուսի» 33-րդ 42-46 թաղամասի ներդրումային նախագիծը, որը  նախատեսում է կառուցել համալիր։ Այն կգտնվի Երևան քաղաքի սրտում՝ Հանրապետության հրապարակին հարակից տարածքում:",
        "img": "../img/photo.jpg", 
    },
    {   "id": "2",
        "text": " Մեր հաջորդ պատվիրատուն է ՝ «ԲԱՅԱԶԵՏ ՔՈՆՍԹՐԱՔՇՆ» ՍՊԸ–ն ,որի նախագիծն է «ԱՐԵՎ» բնակելի համալիրը։ Երևանի հենց սրտում ՝ Խանջյան 9/3 հասցեում է  գտնվում այս գեղեցիկ շենքը, որն ունի  3 հասարակական և 14 բնակելի հարկեր։",
        "img": "../img/photo2.jpg", 
    },
    {   "id": "3",
        "text": "«Հարությունյան Շին» ՍՊԸ-ն կատարում է շինարարական աշխատանքներ՝ կապիտալ շինարարություն, ինժեներական համակարգեր, տանիքիների վերանորոգում, գազամատակարարման աշխատանքներ և այլն։ Նրանք մեզ են վստահել Մոնթե Մելքոնյան 10 հասցեում գտնվող շենքի երեսապատման աշխատանքները։",
        "img": "../img/photo1.jpg", 
    },
    
  
 ]