export const infomanue = [
    {
        "id": "1",
        "text": "Նախագծեր",
        "url": "/museum",
    },
    {
        "id": "2",
        "text": "Գործընկերները",
        "url": "/partners",
    },
    {
        "id": "3",
        "text": "Պատվիրատուներ",
        "url": "/customers",
    },
    {
        "id": "4",
        "text": "Կապ",
        "url": "/contacts",
    },


]
