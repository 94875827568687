import './responsive.css'
import Work from "../elements/work";
import "./customers.css";
import {infopart} from "../ul/infopart";

function Partners(params) {
    return (
        <div className="contener">
            <div className="infobox">
                <div className="infoboxTitle">
                    <div className="custbox">
                        <div className="custboxTitle">
                            <div className="titlecust"><h1>Մեր Գործընկերները</h1></div>
                            <div className="cutsinfo">
                                {infopart.map(itam => {
                                    return (
                                        <Work
                                            key={itam.id}
                                            id={itam.id}
                                            text={itam.text}
                                            img={itam.img}
                                        />
                                    )
                                })}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Partners
